import React, { useState } from "react";
import uuid from "uuid/v4";
import { apiCall } from "../../shared/helpers/networkHelper";
import * as endpoint from "../../shared/utils/endpoints";
import ErrorHandler from "../../shared/classes/ErrorHandler";
import {
    Button,
    Col,
    Descriptions,
    Empty,
    Input,
    Modal,
    Radio,
    Row,
    Spin,
    Table
} from "antd";
import moment from "moment";

// TODO: form validation - tanya pak rolando mana yg required mana yg tidak
export default function ScreeningRawatJalan({ datas, getEmrData, userCode }) {
    const initalFormState = {
        asalRujukan: "",
        alamat: "",
        pernafasan: "",
        getUpAndGoTest: {
            caraBerjalan: "",
            alatBantu: "",
            menopangDiri: ""
        },
        resikoJatuh: "",
        nyeriDada: "",
        skalaNyeri: "",
        batuk: "",
        keputusan: ""
    };
    const [formMode, setFormMode] = useState("add");
    const [showScreeningModal, setShowScreeningModal] = useState(false);
    const [formData, setFormData] = useState(initalFormState);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState("");

    const handleInputChange = key => e => {
        const value = e.target ? e.target.value : e; // Handle Radio and Select values
        setFormData(prevData => ({
            ...prevData,
            [key]: value
        }));
    };

    const handleGetUpAndGoChange = subKey => e => {
        const value = e.target ? e.target.value : e; // Handle Radio values
        setFormData(prevData => ({
            ...prevData,
            getUpAndGoTest: {
                ...prevData.getUpAndGoTest,
                [subKey]: value
            }
        }));
    };

    const handleOk = () => {
        setIsLoading(true);

        const currentDatas = Array.isArray(datas) ? datas : [];

        if (formMode === "add") {
            const newEntry = {
                ...formData,
                uuid: uuid(),
                createdAt: new Date().toISOString()
            };

            const newDatas = [...currentDatas, newEntry];

            apiCall(
                endpoint.GET_CUSTOMER_EMR,
                "put",
                {
                    user_code: userCode,
                    screening_rawat_jalan: newDatas
                },
                result => {
                    getEmrData(userCode);
                    setShowScreeningModal(false);
                    setFormData(initalFormState);
                    setIsLoading(false);
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                    setIsLoading(false);
                }
            );
        } else {
            const updatedDatas = currentDatas.map(entry =>
                entry.uuid === formData.uuid
                    ? {
                          ...entry,
                          ...formData,
                          updatedAt: new Date().toISOString()
                      }
                    : entry
            );

            apiCall(
                endpoint.GET_CUSTOMER_EMR,
                "put",
                {
                    user_code: userCode,
                    screening_rawat_jalan: updatedDatas
                },
                result => {
                    getEmrData(userCode);
                    setShowScreeningModal(false);
                    setFormData(initalFormState);
                    setIsLoading(false);
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                    setIsLoading(false);
                }
            );
        }
    };

    function showDeleteConfirm(record) {
        Modal.confirm({
            title: "Yakin ingin menghapus?",
            content: "Data akan dihapus",
            okText: "Hapus",
            okType: "danger",
            cancelText: "Batal",
            onOk() {
                setIsLoading(true);
                const updatedDatas = datas.filter(
                    entry => entry.uuid !== record.uuid
                );

                apiCall(
                    endpoint.GET_CUSTOMER_EMR,
                    "put",
                    {
                        user_code: userCode,
                        screening_rawat_jalan:
                            updatedDatas.length > 0 ? updatedDatas : "{}"
                    },
                    result => {
                        getEmrData(userCode);
                        setIsLoading(false);
                    },
                    err => {
                        ErrorHandler.handleGeneralError(err);
                        setIsLoading(false);
                    }
                );
            },
            onCancel() {
                console.log("Cancel");
            }
        });
    }

    return (
        <>
            <div style={{ marginBottom: "2rem" }}>
                <Button
                    htmlType="button"
                    size="default"
                    type="primary"
                    onClick={() => {
                        setFormMode("add");
                        setShowScreeningModal(true);
                    }}
                >
                    Buat Screening Rawat Jalan Baru
                </Button>
            </div>

            <h4>Daftar Screening Rawat Jalan Pelanggan</h4>
            <Spin spinning={isLoading}>
                {Array.isArray(datas) && datas.length > 0 ? (
                    <div style={{ overflowX: "auto" }}>
                        <Table
                            dataSource={datas}
                            rowKey={record => record.uuid}
                            key={datas.length}
                        >
                            <Table.Column
                                title="Tanggal Datang"
                                dataIndex="tanggalDatang"
                                key="tanggalDatang"
                                render={(_, record) =>
                                    moment(record.createdAt).format(
                                        "DD-MM-YYYY"
                                    )
                                }
                            />
                            <Table.Column
                                title="Jam Datang"
                                dataIndex="jamDatang"
                                key="jamDatang"
                                render={(_, record) =>
                                    moment(record.createdAt).format("HH:mm:ss")
                                }
                            />
                            <Table.Column
                                title="Asal Rujukan"
                                dataIndex="asalRujukan"
                                key="asalRujukan"
                            />
                            <Table.Column title="Alamat" dataIndex="alamat" />
                            <Table.Column
                                title="Action"
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                setFormData(record);
                                                setFormMode("view");
                                                setShowScreeningModal(true);
                                            }}
                                            style={{ marginRight: 8 }}
                                        >
                                            Lihat
                                        </Button>
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                setFormData(record);
                                                setFormMode("edit");
                                                setShowScreeningModal(true);
                                            }}
                                            type="primary"
                                            style={{ marginRight: 8 }}
                                        >
                                            Ubah
                                        </Button>
                                        <Button
                                            size="small"
                                            onClick={() =>
                                                showDeleteConfirm(record)
                                            }
                                            type="danger"
                                            style={{ marginRight: 8 }}
                                        >
                                            Hapus
                                        </Button>
                                    </>
                                )}
                            />
                        </Table>
                    </div>
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </Spin>

            <Modal
                title={
                    formMode === "add"
                        ? "Add Screening Rawat Jalan"
                        : formMode === "edit"
                        ? "Edit Screening Rawat Jalan"
                        : "View Screening Rawat Jalan"
                }
                visible={showScreeningModal}
                onOk={handleOk}
                onCancel={() => {
                    setShowScreeningModal(false);
                    setFormData(initalFormState);
                }}
                width="75%"
                okText={
                    formMode === "add"
                        ? "Add Screening Rawat Jalan"
                        : formMode === "edit"
                        ? "Edit Screening Rawat Jalan"
                        : "View Screening Rawat Jalan"
                }
                okButtonProps={{ disabled: formMode === "view" }}
                maskClosable={false}
                style={{ overflowX: "auto" }}
            >
                <Spin spinning={isLoading}>
                    <Descriptions
                        column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}
                    >
                        <Descriptions.Item label="Tanggal Datang">
                            {moment().format("DD MMMM YYYY")}
                        </Descriptions.Item>
                        <Descriptions.Item label="Jam Datang">
                            {moment().format("hh:mm")}
                        </Descriptions.Item>
                    </Descriptions>

                    <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
                        <Col xs={24} sm={12}>
                            <div>
                                {formMode === "view" ? (
                                    <span>
                                        Asal Rujukan: {formData.asalRujukan}
                                    </span>
                                ) : (
                                    <Input
                                        addonBefore="Asal Rujukan"
                                        id="asalRujukan"
                                        value={formData.asalRujukan}
                                        onChange={handleInputChange(
                                            "asalRujukan"
                                        )}
                                        style={{ width: "100%" }}
                                    />
                                )}
                            </div>
                        </Col>
                        <Col xs={24} sm={12}>
                            <div>
                                {formMode === "view" ? (
                                    <span>Alamat: {formData.alamat}</span>
                                ) : (
                                    <Input
                                        addonBefore="Alamat"
                                        id="alamat"
                                        value={formData.alamat}
                                        onChange={handleInputChange("alamat")}
                                        style={{ width: "100%" }}
                                    />
                                )}
                            </div>
                        </Col>
                    </Row>

                    <Descriptions
                        title="PEMERIKSAAN"
                        bordered
                        layout={
                            window.innerWidth <= 576 ? "vertical" : "horizontal"
                        }
                        column={1}
                    >
                        <Descriptions.Item label="Pernafasan">
                            {formMode === "view" ? (
                                <span>
                                    {formData.pernafasan === "sesak"
                                        ? "Tampak Sesak"
                                        : formData.pernafasan === "normal"
                                        ? "Nafas Normal"
                                        : ""}
                                </span>
                            ) : (
                                <Radio.Group
                                    value={formData.pernafasan}
                                    onChange={handleInputChange("pernafasan")}
                                >
                                    <Radio value="normal">Nafas Normal</Radio>
                                    <Radio value="sesak">Tampak Sesak</Radio>
                                </Radio.Group>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Get Up & Go Test">
                            <p style={{ marginBottom: "-1px" }}>
                                a. Cara berjalan pasien:
                            </p>
                            <ul>
                                <li>
                                    Tidak seimbang / limbung / sempoyongan?
                                    {formMode === "view" ? (
                                        <span>
                                            {formData.getUpAndGoTest
                                                .caraBerjalan === "ya"
                                                ? " Ya"
                                                : formData.getUpAndGoTest
                                                      .caraBerjalan === "tidak"
                                                ? " Tidak"
                                                : ""}
                                        </span>
                                    ) : (
                                        <Radio.Group
                                            value={
                                                formData.getUpAndGoTest
                                                    .caraBerjalan
                                            }
                                            style={{ marginLeft: "1em" }}
                                            onChange={handleGetUpAndGoChange(
                                                "caraBerjalan"
                                            )}
                                        >
                                            <Radio value="ya">Ya</Radio>
                                            <Radio value="tidak">Tidak</Radio>
                                        </Radio.Group>
                                    )}
                                </li>
                                <li>
                                    Jalan Menggunakan Alat Bantu?
                                    {formMode === "view" ? (
                                        <span>
                                            {formData.getUpAndGoTest
                                                .alatBantu === "ya"
                                                ? " Ya"
                                                : formData.getUpAndGoTest
                                                      .alatBantu === "tidak"
                                                ? " Tidak"
                                                : ""}
                                        </span>
                                    ) : (
                                        <Radio.Group
                                            value={
                                                formData.getUpAndGoTest
                                                    .alatBantu
                                            }
                                            style={{ marginLeft: "1em" }}
                                            onChange={handleGetUpAndGoChange(
                                                "alatBantu"
                                            )}
                                        >
                                            <Radio value="ya">Ya</Radio>
                                            <Radio value="tidak">Tidak</Radio>
                                        </Radio.Group>
                                    )}
                                </li>
                            </ul>
                            <p
                                style={{
                                    marginTop: "4px",
                                    marginBottom: "-1px"
                                }}
                            >
                                b. Menopang saat akan duduk:
                            </p>
                            <ul>
                                <li>
                                    Tampak memegang pinggiran kursi atau meja
                                    atau beda lain sebagai penompang?
                                    {formMode === "view" ? (
                                        <span>
                                            {formData.getUpAndGoTest
                                                .menopangDiri === "ya"
                                                ? " Ya"
                                                : formData.getUpAndGoTest
                                                      .menopangDiri === "tidak"
                                                ? " Tidak"
                                                : ""}
                                        </span>
                                    ) : (
                                        <Radio.Group
                                            value={
                                                formData.getUpAndGoTest
                                                    .menopangDiri
                                            }
                                            style={{ marginLeft: "1em" }}
                                            onChange={handleGetUpAndGoChange(
                                                "menopangDiri"
                                            )}
                                        >
                                            <Radio value="ya">Ya</Radio>
                                            <Radio value="tidak">Tidak</Radio>
                                        </Radio.Group>
                                    )}
                                </li>
                            </ul>
                        </Descriptions.Item>
                        <Descriptions.Item label="Resiko Jatuh">
                            {formMode === "view" ? (
                                <span>
                                    {formData.resikoJatuh === "tidak"
                                        ? "Tidak beresiko"
                                        : formData.resikoJatuh === "rendah"
                                        ? "Risiko rendah"
                                        : formData.resikoJatuh === "tinggi"
                                        ? "Risiko tinggi"
                                        : ""}
                                </span>
                            ) : (
                                <Radio.Group
                                    value={formData.resikoJatuh}
                                    onChange={handleInputChange("resikoJatuh")}
                                >
                                    <Radio value="tidak">Tidak beresiko</Radio>
                                    <Radio value="rendah">Risiko rendah</Radio>
                                    <Radio value="tinggi">Risiko tinggi</Radio>
                                </Radio.Group>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Nyeri Dada">
                            {formMode === "view" ? (
                                <span>
                                    {formData.nyeriDada === "tidak"
                                        ? "Tidak ada"
                                        : formData.nyeriDada === "ada"
                                        ? "Ada (skala sedang)"
                                        : ""}
                                </span>
                            ) : (
                                <Radio.Group
                                    value={formData.nyeriDada}
                                    onChange={handleInputChange("nyeriDada")}
                                >
                                    <Radio value="tidak">Tidak ada</Radio>
                                    <Radio value="ada">
                                        Ada (skala sedang)
                                    </Radio>
                                </Radio.Group>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Skala Nyeri">
                            {formMode === "view" ? (
                                <span>{formData.skalaNyeri}</span>
                            ) : (
                                <Radio.Group
                                    value={formData.skalaNyeri}
                                    onChange={handleInputChange("skalaNyeri")}
                                >
                                    <Radio value="1-3">1 - 3</Radio>
                                    <Radio value="3-5">3 - 5</Radio>
                                    <Radio value="5-8">5 - 8</Radio>
                                    <Radio value="8-10">8 - 10</Radio>
                                </Radio.Group>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Batuk">
                            {formMode === "view" ? (
                                <span>
                                    {formData.batuk === "tidak"
                                        ? "Tidak ada"
                                        : formData.batuk === "<2minggu"
                                        ? "Batuk < 2 Minggu"
                                        : formData.batuk === ">2minggu"
                                        ? "Batuk > 2 Minggu"
                                        : ""}
                                </span>
                            ) : (
                                <Radio.Group
                                    value={formData.batuk}
                                    onChange={handleInputChange("batuk")}
                                >
                                    <Radio value="tidak">Tidak ada</Radio>
                                    <Radio value="<2minggu">
                                        Batuk {"<"} 2 Minggu
                                    </Radio>
                                    <Radio value=">2minggu">
                                        Batuk {">"} 2 Minggu
                                    </Radio>
                                </Radio.Group>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Keputusan">
                            {formMode === "view" ? (
                                <span>
                                    {formData.keputusan === "sesuai"
                                        ? "Sesuai antrian"
                                        : formData.keputusan === "poli"
                                        ? "Poliklinik disegerakan"
                                        : formData.keputusan === "tindakan"
                                        ? "Ruang tindakan"
                                        : ""}
                                </span>
                            ) : (
                                <Radio.Group
                                    value={formData.keputusan}
                                    onChange={handleInputChange("keputusan")}
                                >
                                    <Radio value="sesuai">Sesuai antrian</Radio>
                                    <Radio value="poli">
                                        Poliklinik disegerakan
                                    </Radio>
                                    <Radio value="tindakan">
                                        Ruang tindakan
                                    </Radio>
                                </Radio.Group>
                            )}
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
            </Modal>
        </>
    );
}

