import {
    Button,
    Card,
    DatePicker,
    List,
    Modal,
    Radio,
    Spin,
    Table,
    Form,
    Input,
    message,
    Select, Dropdown, Icon, Menu
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { apiCall } from "../shared/helpers/networkHelper";
import {
    CUSTOMER_REGISTRATION,
    GET_MEMBER_INFO,
    GET_MEMBER_SEARCH
} from "../shared/utils/endpoints";
import ErrorHandler from "../shared/classes/ErrorHandler";
import { empty } from "../shared/helpers/generalHelper";
import QrReader from "react-qr-reader";
import axios from "axios/index";
import { validEmail, validPhone } from "../shared/helpers/stringHelper";
import { MOMENT_SQL_DATE_FORMAT } from "../shared/utils/constants";
import styles from "./CustomerRegistration.module.scss";
import {posOperations} from "../state/ducks/pos";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
    }
};

function CustomerRegistration(props) {
    const [registrationData, setRegistrationData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(moment());
    const [statusFilter, setStatusFilter] = useState('allstatus');
    const [showPatientPickerModal, setShowPatientPickerModal] = useState(false);
    const [formData, setFormData] = useState({
        user_code: "",
        nomor_urut: registrationData.length + 1,
        pendaftaran_time: moment().format("YYYY-MM-DD HH:mm:ss"),
        status: ""
    });
    const [isEditing, setIsEditing] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [selectOptions, setSelectOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formStep, setFormStep] = useState(1);
    const [searchUserLoading, setSearchUserLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [user_identifier_type, setUser_identifier_type] = useState("phone");
    const [user_identifier_string, setUser_identifier_string] = useState();
    const [userSearchResult, setUserSearchResult] = useState(null);

    const { userData } = props;
    const menuAccess = userData.auth_role_accesses;

    // get table datas (registrations of currently selected date)
    const getRegistrationData = () => {
        apiCall(
            CUSTOMER_REGISTRATION +
                `?filter_date=${selectedDate.format("YYYY-MM-DD")}`,
            "get",
            null,
            result => {
                setRegistrationData(result.data);
                setFormData(prevData => ({
                    ...prevData,
                    nomor_urut:
                        result.data.length > 0
                            ? result.data[result.data.length - 1].nomor_urut + 1
                            : 1
                }));
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            }
        );
        apiCall(
            "cashier/customer-flow-status",
            "get",
            null,
            result => {
                setSelectOptions(result.data);
                if (result.data.length > 0) {
                    setFormData(prev => ({
                        ...prev,
                        status: result.data[0].status_name || ''
                    }));
                }
            },
            err => ErrorHandler.handleGeneralError(err)
        );
    };

    // filter registrationData
    const getFilteredRegistrationData = () => {
        if (statusFilter === 'allstatus') {
            return registrationData;
        }
        return registrationData.filter(
            record => record.status === statusFilter
        );
    };

    useEffect(() => {
        getRegistrationData();
    }, [selectedDate]);

    // step 1, search user by various identifier types
    const onSearchCustomer = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (empty(user_identifier_string) || empty(user_identifier_type)) {
            message.error("Please fill out the customer contact.");
            return;
        }

        if (user_identifier_type === "phone") {
            if (!validPhone(user_identifier_string)) {
                message.error("Please input a valid phone number.");
                return;
            }
        }

        if (user_identifier_type === "email") {
            if (!validEmail(user_identifier_string)) {
                message.error("Please input a valid email.");
                return;
            }
        }

        setSearchUserLoading(true);

        apiCall(
            GET_MEMBER_INFO,
            "post",
            {
                user_identifier_type: user_identifier_type,
                user_identifier_string: user_identifier_string
            },
            result => {
                setUser(result.data);
                setFormData(prev => ({
                    ...prev,
                    user_code: result.data.user_id
                }));
                setFormStep(2);
            },
            err => {
                if (
                    user_identifier_type !== "qr" &&
                    user_identifier_type !== "reference"
                ) {
                    setUser({
                        user_code: null,
                        user_name: "",
                        user_gender: "",
                        user_birth_date: null,
                        user_notes: "",
                        user_email_notification: "",
                        retrieve_time: ""
                    });
                    setFormStep(2);
                } else {
                    ErrorHandler.handleGeneralError(err);
                }
            },
            () => {
                setSearchUserLoading(false);
            }
        );
    };

    const onChangeUserIdentifierType = e => {
        setUser_identifier_type(e.target.value);
    };

    const handleQrScan = data => {
        if (data) {
            setUser_identifier_string(data);
            onSearchCustomer();
        }
    };

    const handleQrError = err => {
        console.error(err);
    };

    const onChangeUserIdentifierString = e => {
        setUser_identifier_string(e.target.value);
    };

    const onSearchCustomerByKeyword = (val, e) => {
        e.stopPropagation();
        e.preventDefault();

        if (empty(user_identifier_string) || empty(user_identifier_type)) {
            message.error("Customer harus dipilih.");
            return;
        }

        if (user_identifier_type === "search") {
            if (empty(user_identifier_string)) {
                message.error("Kata kunci pencarian harus diisi.");
                return;
            }
        }

        setSearchUserLoading(true);
        apiCall(
            GET_MEMBER_SEARCH + `?keyword=${user_identifier_string}`,
            "get",
            null,
            result => {
                setUserSearchResult(result.data.members);
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            () => {
                setSearchUserLoading(false);
            }
        );
    };

    const selectSearchedCustomer = user => {
        let user_identifier_type = "";
        let user_identifier_string = "";

        if (!empty(user.user_email)) {
            user_identifier_type = "email";
            user_identifier_string = user.user_email;
            setUser_identifier_type("email");
            setUser_identifier_string(user.user_email);
        }

        if (!empty(user.user_phone)) {
            user_identifier_type = "phone";
            user_identifier_string = user.user_phone;
            setUser_identifier_type("phone");
            setUser_identifier_string(user.user_phone);
        }

        setSearchUserLoading(true);
        apiCall(
            GET_MEMBER_INFO,
            "post",
            {
                user_identifier_type: user_identifier_type,
                user_identifier_string: user_identifier_string
            },
            result => {
                setUser(result.data);
                setFormData(prev => ({
                    ...prev,
                    user_code: result.data.user_id
                }));
                setFormStep(2);
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            () => {
                setSearchUserLoading(false);
            }
        );
    };
    
    // step 2: edit currently selected user (or new user), and submit handler (to patient-membership-activation)
    const handleUserChange = fieldName => e => {
        const value = e.target ? e.target.value : e;
        setUser(prevState => ({
            ...prevState,
            [fieldName]: value
        }));
    };

    const onDatePickerSelected = (date, dateString) => {
        setUser(prevState => ({
            ...prevState,
            user_birth_date: dateString
        }));
    };

    const handleSelectPatient = () => {
        setSearchUserLoading(true);
        apiCall(
            "cashier/patient-membership-activation",
            "post",
            {
                is_edit_user: 1,
                user_name: user.user_name,
                user_gender: user.user_gender,
                user_birth_date: user.user_birth_date,
                user_notes: user.user_notes,
                user_email_notification: user.user_email_notification,
                user_identifier_string: user_identifier_string,
                user_identifier_type: user_identifier_type,
                nik: user.nik
            },
            result => {},
            err => console.error(err),
            () => {setFormStep(3);
                setSearchUserLoading(false);
            }
        );
    };

    // step 3 form change handler & form submit handler (to customer-flow)
    const handleFormChange = (field, value) => {
        setFormData(prevData => ({
            ...prevData,
            [field]: value
        }));
    };

    const handleOK = () => {
        setLoading(true);
        if (isEditing) {
            const previousChangeHistory = JSON.parse(
                currentData.change_history
            );

            apiCall(
                CUSTOMER_REGISTRATION,
                "put",
                {
                    user_business_session_id:
                        currentData.user_business_session_id,
                    status: formData.status,
                    change_history: JSON.stringify([
                        ...previousChangeHistory,
                        {
                            status_before:
                                previousChangeHistory[
                                    previousChangeHistory.length - 1
                                ].status_after,
                            status_after: formData.status,
                            action_date: moment().format("YYYY-MM-DD HH:mm:ss")
                        }
                    ])
                },
                result => {
                    setFormStep(1);
                    
                    getRegistrationData();
                    setShowPatientPickerModal(false);
                    setUser(null);
                    setFormData(prev => ({ ...prev, user_code: "", status: "" }));
                    setUser_identifier_type("phone");
                    setUserSearchResult(null);
                    setUser_identifier_string(undefined);

                    setCurrentData(null);
                    setIsEditing(false);
                },
                err => ErrorHandler.handleGeneralError(err),
                () => setLoading(false)
            );
        } else {
            apiCall(
                CUSTOMER_REGISTRATION,
                "post",
                {
                    ...formData,
                    user_identifier_string: user_identifier_string,
                    user_identifier_type: user_identifier_type,
                    pendaftaran_time: moment().format("YYYY-MM-DD HH:mm:ss"),
                    change_history: JSON.stringify([
                        {
                            status_before: "",
                            status_after: formData.status,
                            action_date: moment().format("YYYY-MM-DD HH:mm:ss")
                        }
                    ])
                },
                result => {
                    setFormStep(1);
                    getRegistrationData();
                    setShowPatientPickerModal(false);
                    setUser(null);
                    setFormData(prev => ({ ...prev, user_code: "", status: "" }));
                    setUser_identifier_type("phone");
                    setUserSearchResult(null);
                    setUser_identifier_string(undefined);
                },
                err => ErrorHandler.handleGeneralError(err),
                () => setLoading(false)
            );
        }
    };

    // delete confirmation dialog & action
    function showDeleteConfirm(record) {
        Modal.confirm({
            title: "Yakin ingin menghapus?",
            content: `Data ${record.user_name} nomor urut ${record.nomor_urut} akan dihapus`,
            okText: "Hapus",
            okType: "danger",
            cancelText: "Batal",
            onOk: async () => {
                try {
                    setLoading(true);
                    await axios({
                        method: "delete",
                        url:
                            "https://staging.api.gdbusiness.id/" +
                            CUSTOMER_REGISTRATION,
                        data: {
                            user_business_session_id: +record.user_business_session_id
                        }
                    });
                    getRegistrationData();
                } catch (err) {
                    ErrorHandler.handleGeneralError(err);
                } finally {
                    setLoading(false);
                }
            },
        });
    }

    return (
        <Card title="Registrasi dan Proses" bordered={false}>
            <Modal
                onCancel={() => {
                    setFormStep(1);
                    setUser_identifier_type("phone");
                    setUserSearchResult(null);
                    setUser(null);
                    setShowPatientPickerModal(false);
                    setIsEditing(false);
                    setUser_identifier_string(undefined);
                    setCurrentData(null);
                    setFormData(prev => ({
                        ...prev,
                        user_code: "",
                        status: ""
                    }));
                }}
                onOk={handleOK}
                visible={showPatientPickerModal}
                maskClosable={false}
                okButtonProps={{ hidden: formStep !== 3 || loading }}
                okText="Submit"
                // footer={null}
            >
                <Spin spinning={searchUserLoading || loading}>
                    {(formStep === 1 || formStep === 2) && (
                        <>
                            <Form
                                disabled={formStep !== 1}
                                onSubmit={onSearchCustomer}
                            >
                                <div className={styles.formItem}>
                                    <h4>Cari Customer Berdasarkan</h4>
                                    <Radio.Group
                                        defaultValue="phone"
                                        buttonStyle="solid"
                                        disabled={user !== null}
                                        value={user_identifier_type}
                                        onChange={onChangeUserIdentifierType}
                                    >
                                        <Radio.Button value="phone">
                                            Phone
                                        </Radio.Button>
                                        <Radio.Button value="qr">
                                            QR Code
                                        </Radio.Button>
                                        <Radio.Button value="email">
                                            Email
                                        </Radio.Button>
                                        <Radio.Button value="reference">
                                            Reference
                                        </Radio.Button>
                                        <Radio.Button value="search">
                                            Nama
                                        </Radio.Button>
                                        <Radio.Button value="nik">
                                            NIK
                                        </Radio.Button>
                                    </Radio.Group>
                                </div>

                                {user_identifier_type === "phone" && (
                                    <div className={styles.formItem}>
                                        <h4>Phone Number</h4>
                                        <Input
                                            addonBefore="+62"
                                            onChange={
                                                onChangeUserIdentifierString
                                            }
                                            value={user_identifier_string}
                                            disabled={user !== null}
                                            size="large"
                                            type="tel"
                                        />
                                    </div>
                                )}

                                {user_identifier_type === "qr" && (
                                    <div className={styles.formItem}>
                                        <h4>Scan QR Code</h4>
                                        <QrReader
                                            onScan={handleQrScan}
                                            onError={handleQrError}
                                            style={{
                                                width: 300,
                                                maxWidth: "100%",
                                                marginBottom: 10
                                            }}
                                        />

                                        <Input
                                            placeholder="QR Code"
                                            onChange={
                                                onChangeUserIdentifierString
                                            }
                                            value={user_identifier_string}
                                            disabled={user !== null}
                                            size="large"
                                        />
                                    </div>
                                )}

                                {user_identifier_type === "email" && (
                                    <div className={styles.formItem}>
                                        <h4>Email</h4>
                                        <Input
                                            onChange={
                                                onChangeUserIdentifierString
                                            }
                                            value={user_identifier_string}
                                            disabled={user !== null}
                                            size="large"
                                            type="email"
                                        />
                                    </div>
                                )}

                                {user_identifier_type === "reference" && (
                                    <div className={styles.formItem}>
                                        <h4>Reference</h4>
                                        <Input
                                            onChange={
                                                onChangeUserIdentifierString
                                            }
                                            value={user_identifier_string}
                                            disabled={user !== null}
                                            size="large"
                                        />
                                    </div>
                                )}

                                {user_identifier_type === "search" && (
                                    <div className={styles.formItem}>
                                        <h4>Nama Customer</h4>
                                        <Input.Search
                                            onSearch={onSearchCustomerByKeyword}
                                            onChange={
                                                onChangeUserIdentifierString
                                            }
                                            value={user_identifier_string}
                                            disabled={user !== null}
                                            enterButton="Cari"
                                            size="large"
                                        />

                                        {!empty(userSearchResult) &&
                                            user === null && (
                                                <List
                                                    style={{
                                                        marginTop: 10
                                                    }}
                                                    bordered
                                                    dataSource={
                                                        userSearchResult
                                                    }
                                                    renderItem={item => (
                                                        <List.Item>
                                                            <div
                                                                style={{
                                                                    cursor:
                                                                        "pointer",
                                                                    display:
                                                                        "block",
                                                                    width:
                                                                        "100%"
                                                                }}
                                                                onClick={() => {
                                                                    selectSearchedCustomer(
                                                                        item
                                                                    );
                                                                }}
                                                            >
                                                                <h4>
                                                                    {
                                                                        item.user_name
                                                                    }
                                                                    {!empty(
                                                                            item.user_id
                                                                        ) &&
                                                                        ` (${item.user_id})`}
                                                                </h4>
                                                                <p>
                                                                    {!empty(
                                                                        item.user_ref
                                                                    ) && (
                                                                        <React.Fragment>
                                                                            Ref:{" "}
                                                                            {
                                                                                item.user_ref
                                                                            }
                                                                            <br/>
                                                                        </React.Fragment>
                                                                    )}
                                                                    {!empty(
                                                                        item.user_phone
                                                                    ) && (
                                                                        <React.Fragment>
                                                                            {
                                                                                item.user_phone
                                                                            }
                                                                            <br/>
                                                                        </React.Fragment>
                                                                    )}
                                                                    {!empty(
                                                                        item.user_email
                                                                    ) && (
                                                                        <React.Fragment>
                                                                            {
                                                                                item.user_email
                                                                            }
                                                                            <br/>
                                                                        </React.Fragment>
                                                                    )}
                                                                    {!empty(
                                                                        item.user_notes
                                                                    ) && (
                                                                        <React.Fragment>
                                                                            {
                                                                                item.user_notes
                                                                            }
                                                                            <br/>
                                                                        </React.Fragment>
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </List.Item>
                                                    )}
                                                />
                                            )}
                                    </div>
                                )}

                                {user_identifier_type === "nik" && (
                                    <div className={styles.formItem}>
                                        <h4>NIK</h4>
                                        <Input
                                            onChange={
                                                onChangeUserIdentifierString
                                            }
                                            value={user_identifier_string}
                                            disabled={user !== null}
                                            size="large"
                                        />
                                    </div>
                                )}

                                {user_identifier_type !== "search" && (
                                    <div className={styles.formItem}>
                                        <Button
                                            type="primary"
                                            block={true}
                                            htmlType="submit"
                                            disabled={formStep !== 1}
                                        >
                                            Cari Customer
                                        </Button>
                                    </div>
                                )}

                            </Form>
                            {formStep === 2 && (
                                <div>
                                    {!empty(user.user_ref) && (
                                        <div {...formItemLayout}>
                                            <label>
                                                <strong>User Ref: {user.user_ref}</strong>
                                            </label>
                                        </div>
                                    )}

                                    <div className={styles.formItem}>
                                        <label>Nama</label>
                                        <Input
                                            type="text"
                                            onChange={handleUserChange(
                                                "user_name"
                                            )}
                                            value={user.user_name}
                                        />
                                    </div>

                                    <div className={styles.formItem}>
                                        <label>Tanggal Lahir</label>
                                        <DatePicker
                                            value={user.user_birth_date ? moment(user.user_birth_date) : null}
                                            format={"YYYY-MM-DD"}
                                            onChange={onDatePickerSelected}
                                            showToday={true}
                                            allowClear={true}
                                        />
                                        <p>
                                            {moment(
                                                user.user_birth_date,
                                                MOMENT_SQL_DATE_FORMAT
                                            ).format("MM-DD") ===
                                                moment().format("MM-DD") && (
                                                <span>
                                                    <span
                                                        role={"img"}
                                                        aria-label={`Happy Birthday`}
                                                    >
                                                        &#127874;
                                                    </span>{" "}
                                                    Selamat Ulang Tahun
                                                </span>
                                            )}
                                        </p>
                                    </div>

                                    <div className={styles.formItem}>
                                        <label>Jenis Kelamin</label>
                                        <Radio.Group
                                            buttonStyle="solid"
                                            value={user.user_gender}
                                            onChange={handleUserChange(
                                                "user_gender"
                                            )}
                                        >
                                            <Radio.Button value={1}>
                                                Laki-laki
                                            </Radio.Button>
                                            <Radio.Button value={2}>
                                                Perempuan
                                            </Radio.Button>
                                        </Radio.Group>
                                    </div>

                                    <div className={styles.formItem}>
                                        <label>Catatan</label>
                                        <Input.TextArea
                                            type="text"
                                            onChange={handleUserChange(
                                                "user_notes"
                                            )}
                                            rows={4}
                                            value={user.user_notes}
                                        />
                                    </div>

                                    <div className={styles.formItem}>
                                        <label>Notification Email</label>
                                        <Input
                                            type="text"
                                            onChange={handleUserChange(
                                                "user_email_notification"
                                            )}
                                            value={user.user_email_notification}
                                        />
                                    </div>

                                    <div className={styles.formItem}>
                                        <label>NIK</label>
                                        <Input
                                            type="text"
                                            onChange={handleUserChange(
                                                "nik"
                                            )}
                                            value={user.nik}
                                        />
                                    </div>

                                    <Button
                                        htmlType="button"
                                        size="default"
                                        type="primary"
                                        block={true}
                                        onClick={handleSelectPatient}
                                        disabled={searchUserLoading}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            )}
                        </>
                    )}
                    {formStep === 3 && (
                        <Form>
                            <div className={styles.formItem}>
                                <h4>User Code</h4>
                                <Input
                                    value={
                                        isEditing
                                            ? currentData.user_code
                                            : formData.user_code
                                    }
                                    disabled
                                    type="text"
                                />
                            </div>
                            <div className={styles.formItem}>
                                <h4>User Name</h4>
                                <Input
                                    value={
                                        isEditing
                                            ? currentData.user_name
                                            : user.user_name
                                    }
                                    disabled
                                    type="text"
                                />
                            </div>
                            <div className={styles.formItem}>
                                <h4>Nomor Urut</h4>
                                <Input
                                    value={
                                        isEditing
                                            ? currentData.nomor_urut
                                            : formData.nomor_urut
                                    }
                                    disabled
                                    type="number"
                                />
                            </div>
                            <div className={styles.formItem}>
                                <h4>Status</h4>
                                <Select
                                    defaultValue={
                                        isEditing
                                            ? currentData.status
                                            : formData.status
                                    }
                                    onChange={value =>
                                        handleFormChange("status", value)
                                    }
                                >
                                    {selectOptions.map((select, index) => (
                                        <Select.Option
                                            key={index}
                                            value={select.status_name}
                                        >
                                            {select.status_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                        </Form>
                    )}
                </Spin>
            </Modal>
            {/* Add Button & DatePicker */}
            <div className={styles.actionButtons}>
                <Button
                    htmlType="button"
                    type="primary"
                    onClick={() => {
                        setSelectedDate(moment());
                        setShowPatientPickerModal(val => !val);
                    }}
                    style={{width: "100%"}}
                >
                    Registrasi dan Proses Baru
                </Button>
                <DatePicker
                    value={selectedDate}
                    defaultValue={moment()}
                    onChange={date => {
                        date !== null
                            ? setSelectedDate(date)
                            : setSelectedDate(moment());
                    }}
                    format="YYYY-MM-DD"
                    style={{ width: "100%" }}
                />
                <Select
                    defaultValue={statusFilter}
                    onChange={value => setStatusFilter(value)}
                    style={{ width: "100%" }}
                >
                    <Select.Option
                        key={"allstatus"}
                        value={"allstatus"}
                    >
                        All Status
                    </Select.Option>
                    {selectOptions.map((select, index) => (
                        <Select.Option
                            key={index}
                            value={select.status_name}
                        >
                            {select.status_name}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            {/* Table */}
            <Table
                dataSource={getFilteredRegistrationData()}
                rowKey={record => record.pendaftaran_time}
                key={statusFilter}
            >
                <Table.Column
                    title="Nomor Urut"
                    dataIndex="nomor_urut"
                    key="nomor_urut"
                />
                <Table.Column
                    title="Nama Customer"
                    dataIndex="user_name"
                    key="user_name"
                />
                <Table.Column
                    title="Waktu Pendaftaran"
                    dataIndex="pendaftaran_time"
                    key="pendaftaran_time"
                />
                <Table.Column title="Status" dataIndex="status" key="status" />
                <Table.Column
                    title="Action"
                    key="action"
                    render={(text, record) => (
                        <>
                            <Button
                                size="small"
                                onClick={() => {
                                    setUser(undefined);
                                    setFormStep(3);
                                    setIsEditing(true);
                                    setCurrentData(record);
                                    setFormData(prev => ({
                                        ...prev,
                                        status: record.status
                                    }));
                                    setShowPatientPickerModal(true);
                                }}
                                type="primary"
                                style={{ marginRight: 8 }}
                            >
                                Ubah Status
                            </Button>
                            <Button
                                size="small"
                                onClick={() => showDeleteConfirm(record)}
                                type="danger"
                                style={{ marginRight: 8 }}
                            >
                                Hapus
                            </Button>
                            <Dropdown overlay={(
                                <Menu onClick={() => {}}>
                                    {menuAccess?.CASHIER_SYSTEM === 1 && (
                                        <Menu.Item key="1" onClick={() => { props.history.push("/pos"); }}>
                                            <Icon type="link" />
                                            Sistem Kasir (POS)
                                        </Menu.Item>
                                    )}
                                    {menuAccess?.CUSTOMER_JOURNAL === 1 && (
                                        <Menu.Item key="2" onClick={() => { props.history.push("/customer-journal/phone/" + record.phone_local); }}>
                                            <Icon type="link" />
                                            Customer Journal
                                        </Menu.Item>
                                    )}
                                    {menuAccess?.INV_STOCK_OUT === 1 && (
                                        <Menu.Item key="3" onClick={() => { props.history.push("/inventory/stock-out"); }}>
                                            <Icon type="link" />
                                            Inventory - Stock Out
                                        </Menu.Item>
                                    )}
                                </Menu>
                            )}>
                                <Button size={"small"}>
                                    Actions <Icon type="down" />
                                </Button>
                            </Dropdown>
                        </>
                    )}
                />
            </Table>
        </Card>
    );
}

const mapStateToProps = state => {
    return {
        userData: state.authState.userData,
    };
};

const mapDispatchToProps = {
    setTrxProps: posOperations.setTrxProps,
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerRegistration));
